import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Match } from '@reach/router';
import BlogPage from '../templates/BlogPage';

export interface IAppProps {}

const Draft = () => {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (typeof window !== `undefined`) {
      setLoading(false);
    }
  });
  if (loading) {
    return <p>Loading...</p>;
  }
  return (
    <div>
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <Match path="/draft/:id">
        {(props) => {
          if (!props.match) {
            return <div />;
          } else {
            return <BlogPage id={props.match.id} />;
          }
        }}
      </Match>
    </div>
  );
};

export default Draft;
